import React, { useEffect, useState, useRef, useContext } from "react";
import { formatDay } from '../../../../utils';
import { CompletedTag } from '../../../../components';
import { content_groupActions } from '../../../../actions';
import { connect, useDispatch } from "react-redux";
import {
  ContentLabel
} from '../../../../components';
import { WorkspaceShowContext } from "../../../../contexts/WorkspaceShowContext";

function MobileEventCalendarCard(props) {
  const {
    workspace_id,
    can_access_edit_label,
    labelDrpdwnContainerRef,
    showLabelDrpdwn,
    setShowLabelDrpdwn,
    event
  } = props;
  const dispatch = useDispatch();
  let drpdwnContainerRef = useRef();

  const {
    handleOpenContentGroupPopUp,
    handleOpenLabelDrpdwn
  } = useContext(WorkspaceShowContext);

  const handleLabelDelete = (label_id, e) => {
    e.stopPropagation();

    let requestParams = { label_id: label_id, assigned: false }
    let callback = () => {}
    let content_popup_notification = undefined;

    dispatch(content_groupActions.assignContentLabelToContentGroup(workspace_id, event?.id, requestParams, content_popup_notification, callback))
  }

  const handleClickLabel = (event) => {
    if(!can_access_edit_label){
      return;
    }
    let target_position = drpdwnContainerRef.current?.getBoundingClientRect();

    handleOpenLabelDrpdwn(drpdwnContainerRef.current, event.id, event.labels, labelDrpdwnContainerRef, showLabelDrpdwn, setShowLabelDrpdwn)
  }

  return (
    <div
      className="mobile-calendar_card w-100 mb-3"
      key={'mobile-calendar_event' + event.id}
    >

      <div className="d-flex justify-content-between mb-2">
        <CompletedTag show={event?.completed} useVisibility/>
        <div className="mobile-calendar_card-dueDate">
          {
            formatDay(event.due_date, 'hh:mm a')
          }
        </div>
      </div>

      <div
        className="mobile-calendar_card-title text-truncate cursor-pointer"
        onClick={() => handleOpenContentGroupPopUp(event.id)}
      >
        {event.name}
      </div>

      <div
        className=""
        ref={drpdwnContainerRef}
        onClick={() => handleClickLabel(event) }
      >
        {
          event.labels.map((label, index) => (
            <ContentLabel
              id={label.id}
              name={label.name}
              editable={can_access_edit_label}
              color_hex={label.color_hex}
              handleDelete={handleLabelDelete}
              sharesPopup
            />
          ))
        }
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {  })(MobileEventCalendarCard);
