import React from "react";
import { NavDropdown } from 'react-bootstrap';
import CheckIcon from '../../images/icons/check.svg';
import { ElementTooltip } from '../../components';
import { tooltipConstants } from '../../constants';

const renderName =(option, optionname) =>{
  switch(optionname){
    case "user":
     return option.first_name+" "+option.last_name;
    case "user-w-email":
      return option.first_name+" "+option.last_name+" ("+option.email+")"
    default:
      return option.name
  }
}

const handleClick = (event, input, value) => {
  input.onChange(value)
}

export const selectField = (props) => {
  const {
    input,
    label,
    blank_label,
    exclude_blank_option,
    options, optionname,
    tooltip,
    type,
    meta: { touched, error },
    ...rest } = props;
  const selectedOptions = options && options.filter((option) => option.id == input.value)
  const selectedOption = selectedOptions && selectedOptions.length > 0 && selectedOptions[0]

  const renderOption = (option) => {
    return <NavDropdown.Item key={option.id} onClick={(e) => handleClick(e, input, option.id) }>
            { renderName(option, optionname) }
            { option.id == input.value &&
              <span className="dropdown__selected--with-check">
                <img src={CheckIcon} alt="Selected" />
              </span>
            }
          </NavDropdown.Item>
  }


  const renderTooltip = (option) => {
    switch(option?.name.toLowerCase()) {
      case 'team lead':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_TEAM_LEAD}
                  id={'select-team_lead'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>

      case 'coordinator':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_COORDINATOR}
                  id={'select-coordinator'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>

      case 'production':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_PRODUCTION}
                  id={'select-production'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>

      case 'spectator':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_SPECTATOR}
                  id={'select-spectator'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>

      case 'approver':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_APPROVER}
                  id={'select-approver'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>
      case 'contributor':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_CONTRIBUTOR}
                  id={'select-contributor'}
                  className={'unselected-tooltip d-flex justify-content-between'}
               >
              </ElementTooltip>

      default:
        return null
    }
  }


  return (
    <div className="position-relative">
      { label &&
        <label className="mb-1" style={{fontSize: "14px"}}> {label} </label>
      }

      <NavDropdown {...input} title={ selectedOption ? selectedOption.name : blank_label } className={ "form-control dropdown--with-check dropdown--with-tooltip" + (rest.input_class_name ? " " + rest.input_class_name : "") }>
        { !exclude_blank_option &&
          <NavDropdown.Item key="" onClick={(e) => handleClick(e, input, "") }>
            { blank_label }

            { "" == input.value &&
              <span className="dropdown__selected--with-check">
                <img src={CheckIcon} alt="Selected" />
              </span>
            }
          </NavDropdown.Item>
        }

        { options && options.map((option, index) => (
            <NavDropdown.Item
              key={option.id}
              onClick={(e) => handleClick(e, input, option.id) }
              className={'d-flex justify-content-between'}
            >
              <span>{ renderName(option, optionname) }</span>

              {
                tooltip &&
                renderTooltip(option)
              }
            </NavDropdown.Item>
          ))
        }
      </NavDropdown>

      {touched && error && (
      <span className="form-error">{error}</span>
      )}
    </div>
  )
}

export default selectField
