import { useState, useRef } from 'react';

function ProgressIcon(props) {
  const {
    content_group
  } = props;

  return (
    <>
      { !content_group.stage.approved ?
          content_group.stage.status == "pending" ?
            <span className="content_group_icon_status-icon rejected"><i className="fa fa-thumbs-o-down text-white" aria-hidden="true"></i></span>
          :
            <span className="content_group_icon_status-icon submitted"><i className="fa fa-check text-white" aria-hidden="true"></i></span>
        :
          <span className="content_group_icon_status-icon approved"><i className="fa fa-thumbs-o-up text-white" aria-hidden="true"></i></span>
      }
    </>
  );
}

export { ProgressIcon };
