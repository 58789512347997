import { useState, useRef } from 'react';
import { AlertTimeIcon } from './';

function CompletedTag(props) {
  const {
    show,
    useVisibility,
    className
  } = props;

  const style = () => {
    if(useVisibility === undefined) return {};

    return { visibility: show ? 'visible' : 'hidden'}
  }

  if((show === false || show === undefined ) && useVisibility === undefined) return null;

  return (
    <div className={`tag d-flex align-items-center completed-tag`}
         style={style()}>
          <AlertTimeIcon />
          Completed
    </div>
  );
}

export { CompletedTag };
