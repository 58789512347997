import React, { useEffect, useContext, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Popup, UserThumbnails, ElementTooltip } from '../../../../components';
import WorkSpaceEdit from '../../WorkSpacePage/edit';
import { work_spaceActions } from "../../../../actions";
import WorkspaceUsersPopup from '../../CollaboratorPage/list';
import ParticipantIcon from "../../../../images/icons/participant.svg";
import { tooltipConstants } from "../../../../constants/";
import { Link } from 'react-router-dom';
import { history } from '../../../../helpers';
import { TopbarContext } from '../../../../contexts/TopbarContext';

function WorkspaceInfo(props) {
  const {
    currentWorkspace,
    work_space_error,
    current_organization,
    can_access_user_add_invite
  } = props;
  const [workspace_logo_cached, setWorkspaceLogoCached] = useState(false);
  const [workspace_logo, setWorkspaceLogo] = useState();
  const dispatch = useDispatch();
  const isUserRole = localStorage.getItem('user_role');
  const {
    setActiveOffCanvas,
  } = useContext(TopbarContext);
  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });
  const [deletePopup, setDeletePopup] = useState({
    show: false,
    id: null,
  });
  const [showWorkspaceUsersPopup, setShowWorkspaceUsersPopup] = useState({
    show: false,
    id: null
  });

  useEffect(() => {
    if(currentWorkspace?.logo?.thumb_url){
      setWorkspaceLogo(currentWorkspace?.logo?.thumb_url);
      setWorkspaceLogoCached(true);
    }
  }, []);

  useEffect(() => {
    if(workspace_logo_cached){
      return;
    }

    if (currentWorkspace?.logo?.thumb_url) {
      setWorkspaceLogo(currentWorkspace?.logo.thumb_url);
      setWorkspaceLogoCached(true);
    }

  }, [currentWorkspace?.logo]);

  const handleCloseEditForm = () => {
    setPopUpEditForm({ show: false, id: null });
    setWorkspaceLogoCached(false);
  }

  const handleCloseWorkspaceUsersPopup = () => {
    setShowWorkspaceUsersPopup({ show: false, id: null })
  }

  const handleShowEditForm = (id) => {
    dispatch(work_spaceActions.editWorkSpace(id, setPopUpEditForm));
  }

  const handleShowDeletePopup = (id) => {
    setDeletePopup({ show: true, id });
  }

  const handleDelete = () => {
    dispatch(work_spaceActions.destroyWorkSpace(deletePopup.id));
    setDeletePopup({ show: false, id: null });
  }

  const handleCloseDeletePopup = () => {
    setDeletePopup({ show: false, id: null });
  }

  const handleShowWorkspaceUsersPopup = (id) => {
    setShowWorkspaceUsersPopup({ show: true, id: id })
  }

  const handleViewUsers = () => {
    history.push(`/organizations/${current_organization.id}/admin/users`)
    setActiveOffCanvas(false)
  }

  if(currentWorkspace === null || currentWorkspace === undefined){
    return null;
  }

  return (
    <>
      <div
        className="workspace-info_container"
      >
        <div
          className="workspace-title font-weight-bold mb-4"
        >
          {
            workspace_logo &&

            <img
              className="workspace-logo"
              src={workspace_logo}
            />
          }

          {currentWorkspace?.name}
        </div>

        <div
          className="mobile-button mb-4"
        >
          <div className="d-flex align-items-center cursor-pointer">
            <img src={ParticipantIcon} className="mobile-icon"/>
            Users

            <ElementTooltip
              tooltip_title={'Users'}
              tooltip_text={tooltipConstants.INVITED_USER}
              id={'sidebar-mobile-invite_user'}
              className={'ml-1'}
            />
          </div>

          <div
            className="mobile-participants_container"
            onClick={() => can_access_user_add_invite ? handleShowWorkspaceUsersPopup(currentWorkspace.id) : null}
          >
            <UserThumbnails users={currentWorkspace?.workspace_users} tooltip={true} disable_users_info={true}/>
          </div>
        </div>

        <div
          className="mobile-button mb-4"
          onClick={() => handleShowEditForm(currentWorkspace.id)}
        >
          <div
            className="d-flex align-items-center cursor-pointer"
          >
            <i className="fa fa-cog mobile-icon" aria-hidden="true"></i>
            Settings
          </div>
        </div>

          {
            current_organization && isUserRole === "admin" &&
            <div
              className="mobile-button"
              onClick={handleViewUsers}
            >
              <div className="cursor-pointer">
                <img src={ParticipantIcon} className="mobile-icon"/>
                View Users
              </div>
            </div>

          }

        <div
          className="mobile-button mb-4 d-none"
          onClick={() => handleShowDeletePopup(currentWorkspace.id)}
        >
          <div
            className="d-flex align-items-center cursor-pointer"
          >
            <i className="fa fa-trash-o mobile-icon" aria-hidden="true"></i>
            Delete
          </div>
        </div>
      </div>

      <hr/>

      {
        popupEditForm.show &&
        <WorkSpaceEdit
          workspace_id={popupEditForm.id}
          handleCloseEditForm={handleCloseEditForm}
          work_space_error={work_space_error}
        />
      }

      {
        showWorkspaceUsersPopup.show &&
        <WorkspaceUsersPopup
          workspaceId={showWorkspaceUsersPopup.id}
          handleClose={handleCloseWorkspaceUsersPopup}
        />
      }

      {
        deletePopup.show &&
        <Popup
          handleClose={handleCloseDeletePopup}
          handleDelete={handleDelete}
        />
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    currentWorkspace: state.work_space.currentWorkSpace,
    current_organization: state.organization.current_organization,
    work_space_error: state.work_space.work_space_error,
    can_access_user_add_invite: state.work_space.can_access_user_add_invite
  };
};

export default connect(mapStateToProps)(WorkspaceInfo);
