import { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { NavDropdown, Dropdown } from 'react-bootstrap';
import CheckIcon from '../images/icons/check.svg';
import { useMediaQuery } from 'react-responsive';
import { mediaQueryConstants } from '../constants';
import { Modal, Button } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton'

function StageLabel(props) {
  const {
          name,
          can_access_edit_title,
          stages,
          stageParentRef,
          scrollable,
          alignment
        } = props;
  const selectRef = useRef();
  const dropdownRef = useRef();
  const [dropdownStyle, setDropdownStyle] = useState({ bottom: 0, width: 0 });
  let parent_bounds = stageParentRef?.current?.getBoundingClientRect();
  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` })
  const [showMobilePopup, setShowMobilePopup] = useState(false);

  const handleSelect = (e, stageId) => {
    props.handleInlineEdit(function() {}, { stage_id: stageId })
  }

  const handleSelectMobile = (stageId) => {
    setShowMobilePopup(false)
    props.handleInlineEdit(function() {}, { stage_id: stageId })
  }

  const textAlignment = () =>{
    if(alignment === undefined) return 'text-center';

    return "text-"+alignment;
  }

  useEffect(() => {
    if (parent_bounds){
      setDropdownStyle({width: parent_bounds?.width, bottom: parent_bounds?.bottom + 20 })
    }
  }, [stageParentRef?.current, parent_bounds?.bottom, parent_bounds?.width, scrollable]);

  const openMobilePopup = () => {
    if(!isLargeScreen) return;

    setShowMobilePopup(true);
  }

  const handleCloseMobilePopup = () => {
    setShowMobilePopup(false)
  }

  const getStyle = () => {
    if(isLargeScreen) return { display: 'none' };

    if (scrollable) {
      return {
        width: `${dropdownStyle.width}px`,
        maxHeight: `calc(100vh - 480px)`,
        overflowY: 'auto'
      }
    } else {{}{}
      return {
        width: `${parent_bounds?.width}px`
      }
    }
  }

  return (
    <>
      { can_access_edit_title ?
        <>
          <div ref={selectRef}>
            <Dropdown
              className="content__stage-dropdown p-1 dropdown--with-check"
              ref={dropdownRef}
              onClick={openMobilePopup}
            >
              <Dropdown.Toggle
                variant=""
                className={`${textAlignment()} p-0 text-truncate w-100`}
              >
                {name}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={getStyle()}
                className="stage-list__dropdown vertical-scroll_thin"
              >
                { stages && stages.map((opt, index) => (
                    <Dropdown.Item key={opt.id} onClick={(e) => handleSelect(e, opt.id) } className={'text-truncate'}>
                      { opt.name }

                      { opt.name == name &&
                        <span className="dropdown__selected--with-check">
                          <img src={CheckIcon} alt="Selected" />
                        </span>
                      }
                    </Dropdown.Item>
                  ))
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <Modal
            show={showMobilePopup}
            onHide={handleCloseMobilePopup}
            keyboard={true}
            centered
            className="stage-list__modal"
          >
            <Modal.Header closeButton>
              <Modal.Title
                className="font-weight-bold stage-list__modal__title"
              >
                Stage
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <ul className="stage-list__modal__list vertical-scroll_thin modal-list">
                { stages && stages.map((opt, index) => (
                    <li
                      key={'stage_label'+opt.id}
                      onClick={(e) => handleSelectMobile(opt.id) }
                      className={'d-flex cusor-pointer justify-content-between text-truncate my-2'}
                    >
                      { opt.name }

                      { opt.name == name &&
                        <span className="dropdown__selected--with-check">
                          <img src={CheckIcon} alt="Selected" height="16"/>
                        </span>
                      }
                    </li>
                  ))
                }
              </ul>
            </Modal.Body>
          </Modal>
        </>
      :
        <div className="content-group__readonly-stage text-truncate">
          {name}
        </div>
      }
    </>
  );
}

export { StageLabel };
