import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { work_spaceActions, organizationActions } from '../../../actions';
import { Button, Table, Row, Col, Dropdown, ProgressBar } from 'react-bootstrap';
import {
  Popup,
  SortIcon,
  CircleAddIcon,
  LoadSpinner,
  BasicSpinner,
  OrganizationInlineEdit
} from '../../../components';
import { adminBaseURL } from '../../../utils/commonUtils';
import WorkSpaceNew  from '../WorkSpacePage/new';
import WorkSpaceEdit  from '../WorkSpacePage/edit';
import OrganizationNew from '../OrganizationPage/new';
import Sidebar from './sidebar';
import { WorkspaceListContext } from '../../../contexts/WorkspaceListContext';
import { SidebarContext } from '../../../contexts/SidebarContext';
import '../../stylesheets/components/workspace_sidebar.scss';
import MoreVertival from '../../../images/more-vertical.svg';
import { buildFormDataFromObject } from '../../../utils/commonUtils';
import { TopbarContext } from '../../../contexts/TopbarContext';

function progressBarVariant(total_size){
  if(total_size <= 49){
    return 'success'
  } else if (total_size <= 50 && total_size < 80) {
    return `warning`
  } else {
    return 'danger'
  }
}

function WorkSpaceList(props) {
  const dispatch = useDispatch();
  const {
          organizations,
          work_spaces,
          can_crud_workspace,
          initialData,
          can_create_workspace,
          can_create_organization,
          can_update_organization,
          can_destroy_organization,
          current_organization,
          workspace_loading
        } = props
  const location = useLocation();
  const { organization_id } = useParams();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const work_space_error = useSelector(state => state.work_space);
  const default_modes = ['personal', 'shared', 'business']
  const [selected_organization, setSelectedOrganization] = useState(organization_id ? organization_id : default_modes[0]);
  const [selected_tab, setSelectedTab] = useState(organization_id ? default_modes[2] : default_modes[0]);
  const [organization_new_form_popup, setOpenOrganizationNewFormPopup] = useState({ show: false });
  const [organization_destroy_popup, setOpenOrganizationDestroyPopup] = useState({ id: null, show: false });
  const { setIsHomePage } = useContext(TopbarContext);
  const {
    selected_sidebar_organization,
    setSelectedSidebarOrganization,
    selected_sidebar_tab,
    setSelectedSidebarTab
  } = useContext(SidebarContext);

  const handleOpenOrganizationNewPopup = () => {
    setOpenOrganizationNewFormPopup({ show: true })
  }

  const handleCloseOrganizationNewPopup = () => {
    setOpenOrganizationNewFormPopup({ show: false })
  }

  const handleOpenOrganizationDeletePopup = (id) =>{
    setOpenOrganizationDestroyPopup({ show: true, id: id });
  }

  const handleCloseOrganizationDeletePopup = () =>{
    setOpenOrganizationDestroyPopup({ show: false, id: null });
  }

  const handleDeleteOrganization = () => {
    const successCallback = () => {
      window.location = '/'
    }

    dispatch(organizationActions.destroyOrganization(organization_destroy_popup.id, successCallback));
  }


  let workspaceListContext = {
    default_modes: default_modes,
    selected_organization: selected_organization,
    setSelectedOrganization: setSelectedOrganization,
    selected_tab: selected_tab,
    setSelectedTab: setSelectedTab,
    handleOpenOrganizationNewPopup: handleOpenOrganizationNewPopup
  }

  const sort_options =[
    "recently",
    "alphabetically"
  ];

  useEffect(() => {
    setIsHomePage(true);
    if(selected_organization){
      dispatch(organizationActions.getWorkspaces(selected_organization));
    }
  }, []);

  useEffect(() => {
    if(selected_organization && selected_organization !== 0){

      if(default_modes.indexOf(selected_organization) === -1){
        dispatch(organizationActions.getOrganizationDetail(selected_organization))
      }

      dispatch(organizationActions.getWorkspaces(selected_organization));
    }
  }, [selected_organization]);

  useEffect(() => {
    if(selected_tab && selected_tab !== default_modes[2]){
      dispatch(organizationActions.getWorkspaces(selected_tab));
    }
  }, [selected_tab]);


  /*Changes from mobile sidebar*/
  useEffect(() => {
    if(selected_sidebar_tab){
      setSelectedTab(selected_sidebar_tab)
    }
  }, [selected_sidebar_tab])

  useEffect(() => {
    if(selected_sidebar_organization){
      setSelectedOrganization(selected_sidebar_organization)
    }
  }, [selected_sidebar_organization])

  const deleteWorkSpace = (id) => {
    dispatch(work_spaceActions.destroyWorkSpace(id));
  }
  /*
    Popup New Form Modal

  */

  const [popupNewForm, setPopUpNewForm] = useState({
    show: false
  });;

  const handleCloseNewForm = () => {
    setPopUpNewForm({ show: false });
  }

  const handleShowNewForm = () => {
    dispatch(work_spaceActions.workspaceInitial(setPopUpNewForm));
  }

  /*
    Workspace Popup Edit Form Modal
  */

  const [popupEditForm, setPopUpEditForm] = useState({
    show: false,
    id: null
  });;

  const handleCloseEditForm = () => {
    dispatch(organizationActions.getWorkspaces(selected_organization));
    setPopUpEditForm({ show: false, id: null });
  }

  const handleShowEditForm = (id) => {
    dispatch(work_spaceActions.editWorkSpace(id, setPopUpEditForm));
  }

  /*
    Workspace Delete Popup
  */

  const handleShowDeletePopup = (id) => setDeletePopup({ show: true, id });

  const [deletePopup, setDeletePopup] = useState({
    show: false,
    id: null,
  });

  const handleCloseDeleteForm = () =>{
    setDeletePopup({ show: false, id: null });
  }

  const handleDelete = () => {
    dispatch(work_spaceActions.destroyWorkSpace(deletePopup.id));
    dispatch(organizationActions.getWorkspaces(selected_organization));
    handleCloseDeleteForm();
  }

  /*
    Sort Workspaces
  */

  let sorted_workspaces = [];

  const [sortValue, setSortValue] = useState(sort_options[1]);

  switch(sortValue){
    case sort_options[0]:
      sorted_workspaces = work_spaces ?
        work_spaces.sort((a, b) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )
      :
        [];
      break;

    case sort_options[1]:
      sorted_workspaces = work_spaces ?
        work_spaces.sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      :
        [];
      break;

    default:
      sorted_workspaces = work_spaces;
  }

  const handleWorkspaceSort = () =>{
    if(sortValue === sort_options[0]){
      setSortValue(sort_options[1])
    } else{
      setSortValue(sort_options[0])
    }
  }

  let hasWorkspace = sorted_workspaces && sorted_workspaces.length > 0;

  const renderActiveOrganizationTitle = () =>{
    if(selected_tab === 'personal'){
      return <h2
                className="organization_title font-weight-bold text-truncate"
             >
                My Workspace
             </h2>
    }

    if(current_organization){

      if(can_update_organization){
        return <OrganizationInlineEdit
                  organization_title={current_organization?.name}
                  handleInlineEdit={handleOrganizationInlineEdit}
                  organization_id={current_organization?.id}
                />
      } else {
        return <h2
                  className="organization_title font-weight-bold text-truncate"
               >
                  {
                    current_organization?.personal && current_organization?.owner ?
                      "My Workspace"
                    :
                      current_organization?.name
                  }
               </h2>
      }
    }

    if (selected_tab === 'shared'){
      return <h2
                className="organization_title font-weight-bold text-truncate"
             >
                Shared
             </h2>
    }

    // TODO: Find Organization Name here using selected_organization_id

    return <h2
              className="organization_title font-weight-bold text-truncate"
           >
              Workspaces
           </h2>
  }

  const getOrganizationName = (org_id) => {
    if (organizations.length > 0 && org_id){
      let organization = organizations.find((org) => org.id === org_id)

      if(organization){
        return organization.name
      }
    }
  }

  const handleOrganizationInlineEdit = (organization_id, values) => {
    let formData = new FormData();
    buildFormDataFromObject(formData, values, "organization");

    const successCallback = () =>{
      dispatch(organizationActions.getOrganizations());
      dispatch(organizationActions.getWorkspaces(organization_id));
    }

    dispatch(organizationActions.updateOrganization(organization_id, formData, successCallback));
  }

  if (props.loading) {
    return <LoadSpinner />
  }


  return (
    <div className="container layout__container-wrapper" style={{ height: "100%" }}>
      <WorkspaceListContext.Provider value={workspaceListContext}>
        <div className="col-lg-12">
          <Row className="p-3">
            <Col lg={3} className="d-none d-lg-block">
              <Sidebar/>
            </Col>
            <Col lg={9} className="pl-3">
              <Row className="mb-3 g-0">
                <Col
                  sm={12}
                  lg={can_create_workspace && sorted_workspaces.length > 1 ? 5 : 8}
                  className={"d-flex align-items-center pl-0"}
                >
                  {renderActiveOrganizationTitle()}

                  {
                    can_destroy_organization && current_organization &&
                    <Dropdown className="align-self-center d-inline-block">
                      <Dropdown.Toggle variant="primary" id="dropdown-basic" className="btn-transparent" title="Options">
                        <img src={MoreVertival}/>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{right: "auto"}}>
                        {
                          can_destroy_organization &&

                          <Button
                            variant="secondary"
                            className="dropdown-item"
                            onClick={() => handleOpenOrganizationDeletePopup(current_organization.id)}
                          >
                            Delete
                          </Button>
                        }

                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>

                <Col sm={12} lg={can_create_workspace && sorted_workspaces.length > 1 ? 7 : 4} className="workspace_button-group d-flex justify-content-end">
                  {can_create_workspace && hasWorkspace && (
                    <Button
                      onClick={handleShowNewForm}
                      className="btn btn-primary"
                    >
                      <span className="mr-4">
                        <CircleAddIcon
                          color={"#fff"}
                          height={"14"}
                          width={"14"}
                        />
                      </span>
                      Add Workspace
                    </Button>
                  )}
                  { hasWorkspace && sorted_workspaces.length > 1 &&
                    <select className="form-select d-none" onChange={(e) => setSortValue(e.target.value)} defaultValue={sortValue} style={{display: 'inline-block', width: 'auto'}}>
                      <option value={sort_options[0]}>Recently Updated</option>
                      <option value={sort_options[1]}>A-Z</option>
                    </select>
                  }
                  {
                    hasWorkspace && sorted_workspaces.length > 1 &&
                    <Button
                      variant={sortValue === sort_options[0] ? "primary" : "light"}
                      className="ml-4"
                      onClick={handleWorkspaceSort}
                    >
                      <span className="mr-4">
                        <SortIcon
                          color={sortValue === sort_options[0] ? "#fff" : "#727580"}
                          height={'14'}
                          width={'14'}
                        />
                      </span>
                      Recently Updated
                    </Button>
                  }

                </Col>
              </Row>
              <Row>
                {hasWorkspace
                  ?
                  sorted_workspaces.map((data, index) => (

                    <Col sm={"12"} className="mb-3 card workspace_card" key={index}>
                      <Row>
                        <Col sm={"12"} xl={"2"} style={{padding: 0}}>
                          { data.logo &&
                            <Link to={{ pathname: `/organizations/${data.organization_id}${workspaceBaseUrl}/${data.id}/list`}}>
                              <div className="workspace_card-image_container" style={{backgroundImage:"url(" + data.logo.medium_url + ")" }}>
                              </div>
                            </Link>
                          }
                        </Col>
                        <Col sm={"12"} xl={"10"} className="py-1 pl-2 workspace_card-details">
                          <Row>
                            <Col sm={"8"} className="mb-2 pt-3">
                              <h3 className="font-weight-bold"><Link to={{ pathname: `/organizations/${data.organization_id}${workspaceBaseUrl}/${data.id}/list`}} className="py-2 workspace_card-details__title">{data.name}</Link></h3>
                            </Col>
                            {
                              selected_organization === default_modes[1] &&
                                  <Col
                                    className="text-right pt-3 workspace_card-organization_title"
                                  >
                                    {
                                      getOrganizationName(data.organization_id)
                                    }
                                  </Col>
                            }
                          </Row>
                          <Row>
                            <Col md={5} className="mb-3">
                              <div  className="mb-2">
                                <div><span>{data?.unread_notifications_count || 0} </span> Unread Notifications</div>
                              </div>

                              <div><span>{data?.message_count || 0} </span> New Messages</div>
                            </Col>
                            <Col md={5}>
                              <div  className="mb-2">
                                <div><span>{data?.content_group_pending_approval_count || 0} </span> Pending Approval</div>
                              </div>
                              <div>
                                <div><span>{data?.content_group_pending_revision_count || 0} </span> Pending Revision</div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <div
                        className="d-flex flex-row-reverse align-items-center workspace-side_details mx-2"
                      >
                        { /*// TODO:  Add setting and delete. Updating workspace should update on the list*/ }
                        {
                          (selected_tab === default_modes[0] || selected_tab === default_modes[1] && data?.can_access_update_workspace) &&
                          <Dropdown className="d-inline-block">
                            <Dropdown.Toggle id="dropdown-basic" className="p-2 btn-transparent" title="Options">
                              <i className="fa fa-cog" aria-hidden="true"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{right: "auto"}}>
                              {
                                data.can_access_update_workspace &&
                                <Button
                                  variant="secondary"
                                  className="dropdown-item"
                                  onClick={() => handleShowEditForm(data.id)}
                                >
                                  Settings
                                </Button>
                              }
                              {
                                data.can_access_destroy_workspace &&

                                <Button
                                  variant="secondary"
                                  className="dropdown-item"
                                  onClick={() => handleShowDeletePopup(data.id)}
                                >
                                  Delete
                                </Button>
                              }

                            </Dropdown.Menu>
                          </Dropdown>
                        }

                        {
                          data.personal &&
                            <div
                              className="text-right personal_workspace-progress m-2"
                            >
                              <ProgressBar variant={progressBarVariant(data.total_size)} now={data.total_size} max={100}/>
                              {data.total_size}% of 100 MB used
                            </div>
                        }
                      </div>

                    </Col>
                  ))
                  :
                  <div className="text-center py-4">
                    {
                      can_create_workspace
                      ?
                        <>
                          <h3>Welcome to your new organization.</h3>
                          <p><Button onClick={handleShowNewForm} variant="primary" size="sm">Create your first workspace.</Button></p>
                        </>
                      :
                        <p>No workspaces available yet.</p>
                    }
                  </div>
                }

                {workspace_loading &&
                  <Col sm={12} className={"pl-0"}>
                    <BasicSpinner />
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </div>
        {
          popupEditForm.show &&
            <WorkSpaceEdit
              initialData={initialData}
              workspace_id={popupEditForm.id}
              handleCloseEditForm={handleCloseEditForm}
              work_space_error={work_space_error}
              sort={sortValue}
            />
        }

        {
          popupNewForm.show &&
          <WorkSpaceNew
            initialData={initialData}
            handleCloseNewForm={handleCloseNewForm}
          />
        }

        {deletePopup.show &&
          <Popup
            handleClose={handleCloseDeleteForm}
            handleDelete={handleDelete}
          />
        }

        {
          organization_new_form_popup.show &&
          <OrganizationNew
            handleCloseForm={handleCloseOrganizationNewPopup}
          />
        }

        {/* Organization Delete Popup */}
        {organization_destroy_popup.show &&
          <Popup
            handleClose={handleCloseOrganizationDeletePopup}
            handleDelete={handleDeleteOrganization}
          />
        }

      </WorkspaceListContext.Provider>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading,
    can_crud_workspace: state.work_space.can_crud_workspace,
    work_spaces: state.work_space.work_spaces,
    initialData: state.work_space.initialData,
    error: state.work_space.error,
    can_create_workspace: state.organization.can_create_workspace,
    can_create_organization: state.organization.can_create_organization,
    can_update_organization: state.organization.can_update_organization,
    can_destroy_organization: state.organization.can_destroy_organization,
    workspace_loading: state.work_space.workspace_dropdown_loading,
    organizations: state.organization.organizations,
    current_organization: state.organization.current_organization
  };
};

export default connect(mapStateToProps, { work_spaceActions })(WorkSpaceList);
