import { useState, useEffect } from 'react';
import { connect } from "react-redux";

function MarkAsCompleteButton(props) {
  const {
    content_group,
    updated_content_group,
    handleInlineEdit
  } = props;
  const [initial_loaded, setInitialLoaded] = useState(false);
  const [mark_as_complete, setAsComplete] = useState(content_group?.completed || false)

  useEffect(() => {
    if(updated_content_group?.id === content_group?.id){
      setAsComplete(updated_content_group.completed)
    }
  }, [updated_content_group]);

  useEffect(() => {
    if(initial_loaded === false){
      setInitialLoaded(true);
      return;
    }

    const searchTimeout = setTimeout(
        function() {
          handleInlineEdit({completed: mark_as_complete})
        }
        .bind(this),
        1000
    );

    return () => clearTimeout(searchTimeout)
  }, [mark_as_complete]);

  const handleSubmit = (e) => {
    setAsComplete(e.target.checked)
  }

  return (
    <label className="mt-0">
      <input type="checkbox"
        name={`completed`}
        checked={mark_as_complete}
        onClick={(e) => {
          handleSubmit(e)
        }}
      />
      <span className="ml-3">Mark as complete</span>
    </label>
  );
}

const mapStateToProps = (state) => {
  return {
    updated_content_group: state.content_group.updated_content_group
  };
};

export default connect(mapStateToProps, { })(MarkAsCompleteButton);
