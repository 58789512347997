import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../helpers';
import { alertActions } from '../actions';
import { AdminRoute, PrivateRoute, WorkspaceRouteWrapper } from '../components';
import UserSummary from '../components/UserSummary'
import { Alert } from '../components/Alert';
import HomePage  from './screens/HomePage/homePage';
import LoginPage from './screens/LoginPage/loginForm';
import RegisterPage from './screens/RegisterPage/registerForm';
import { LiveChatWidget } from '../components';
import RequestUnlockAccount from './screens/RegisterPage/requestUnlock';
import ResendConfirmationMail from './screens/RegisterPage/resendConfirmation';
import Header from './screens/LayoutPage/header';
import WorkspaceBar from './screens/LayoutPage/workspaceBar';
import ContentList from './screens/ContentPage/list';
import ContentShow from './screens/ContentPage/show';
import ContentNew from './screens/ContentPage/new';
import ContentVersion from './screens/ContentPage/versions';
import ContentVersionShow from './screens/ContentPage/version_show';
import ContentReplace from './screens/ContentPage/replace';
import ConfirmPage from './screens/ConfirmationPage/confirmPage';
import ProjectList from './screens/ProjectPage/list';
import WorkspaceList from './screens/WorkSpacePage/list';
import WorkspaceShow from './screens/WorkSpacePage/show';
import PasswordNew from './screens/PasswordPage/passwordNew';
import PasswordEdit from './screens/PasswordPage/passwordEdit';
import UserNew from './screens/MultiUserPage/new';
import UserList from './screens/MultiUserPage/list';
import EditUser from './screens/MultiUserPage/edit';
import CollaboratorList from './screens/CollaboratorPage/list';
import UserProfileForm from './screens/UserProfile/userProfileForm';
import UserPermission from './screens/UserProfile/userPermission';
import StageList from './screens/StagePage/list';
import './App.scss';
import UnlockAccountPage from './screens/UnlockAccountPage/unlock_account';
import { useMediaQuery } from 'react-responsive'
import AcceptInvitation from './screens/WorkSpacePage/acceptInvitation';
import AcceptWorkspaceInvitation from './screens/InvitationsPage/acceptWorkspaceInvitation';
import NotificationsPage from './screens/NotificationPage/list';
import TemplateStageList from './screens/TemplateStagePage/list';
import { adminBaseURL } from '../utils/commonUtils';
import { Row, Col } from 'react-bootstrap';
import ChatWindow from "./screens/ChatPage/chat";
import AssignedUserNew from './screens/AssignedUserPage/new';
import AssignedUserList from './screens/AssignedUserPage/list';
import NotificationActionCableUpdate from "./screens/LayoutPage/notificationActionCable";
import { UserSummaryContext } from '../contexts/UserSummaryContext';
import { useDetectOutsideClick } from "../utils/useDetectOutsideClick";
import { clearSearchHistory } from '../utils'
import { ContentGroupPopupContext } from '../contexts/ContentGroupPopupContext';
import { SearchContext } from '../contexts/SearchContext';
import UserProfileShow from './screens/UserProfile/show';
import OrganizationTracker from './screens/OrganizationPage/organizationTracker';
import { TopbarContext } from '../contexts/TopbarContext';
import MobileSidebar from './screens/LayoutPage/mobile/mobileSidebar';
import { SidebarContext } from '../contexts/SidebarContext';

function App() {
  const isUserLoggedIn = localStorage.getItem('user') ? true : false;
  const isUserRole = localStorage.getItem('user_role')
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const alert = useSelector((state) => state.alert);
  const workspaceBaseUrl = `/organizations/:organization_id${adminBaseURL("workspaces")}`;
  const userProfileBaseUrl = adminBaseURL("user-profiles");
  const templateStageBaseUrl = `/organizations/:organization_id/admin/board-stages`;
  const dispatch = useDispatch();
  const [workspaceBarViewable, setWorkspaceBarViewable] = useState(false);
  const [ is_home_page, setIsHomePage ] = useState(false);

  let searchTimeout = 0;
  const [activeOffCanvas, setActiveOffCanvas] = useState(false);
  const chatElement = document.querySelector('[data-id="zsalesiq"]');

  const [contentGroupPopUp, setContentGroupPopUpState] = useState({id: null, show: false });
  const default_modes = ['personal', 'shared', 'business'];
  const [selected_sidebar_organization, setSelectedSidebarOrganization] = useState(default_modes[0]);
  const [selected_sidebar_tab, setSelectedSidebarTab] = useState(default_modes[0]);
  const [ showNotificationsDropdown, setShowNotificationsDropdown ] = useState(false);

  const setContentGroupPopUp = (object) =>{
    sessionStorage.setItem("previous_url", `${window.location.pathname}${window.location.search}`)
    setContentGroupPopUpState(object)
  }

  const setSidebar = () => {
    if(!isMobileScreen){
      setActiveOffCanvas(!activeOffCanvas)
    }
  }

  useEffect(() => {
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      window.HubSpotConversations.widget.refresh();
    }

    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
      if(location?.pathname !== '/'){
        setIsHomePage(false);
      }
      if (window.HubSpotConversations && window.HubSpotConversations.widget) {
        window.HubSpotConversations.widget.refresh();
      }

    });
    clearSearchHistory();
  }, []);

  useEffect(() => {
    if(chatElement){
      liveChatToggle(window.location.pathname === '/');
    }
  }, [chatElement, is_home_page]);

  const liveChatToggle = useCallback((boolean) => {
    if(chatElement === null || chatElement === undefined) return;

    if(boolean){
      chatElement.style.display = 'block';
    } else {
      chatElement.style.display = 'none';
    }
  }, [chatElement])

  const clearAlert = () => {
    dispatch(alertActions.clear());
  }
  const userSummaryRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(userSummaryRef, false)
  const [userSummaryDetails, setUserSummaryDetails] = useState({ id: null, boundary: {}, user_id: null, workspace_id: null, organization_id: null })
  const searchBarRef = useRef(null);

  let userSummaryContexts = {
    userSummaryDetails: userSummaryDetails,
    setUserSummaryDetails: setUserSummaryDetails,
    isActive: isActive,
    setIsActive: setIsActive
  }

  const contentGroupPopupContexts = {
    contentGroupPopUp: contentGroupPopUp,
    setContentGroupPopUp: setContentGroupPopUp
  }

  const searchContexts = {
    searchBarRef: searchBarRef,
    searchTimeout: searchTimeout
  }

  const topbarContexts = {
    is_home_page: is_home_page,
    setIsHomePage: setIsHomePage,
    activeOffCanvas: activeOffCanvas,
    setActiveOffCanvas: setActiveOffCanvas,
    showNotificationsDropdown: showNotificationsDropdown,
    setShowNotificationsDropdown: setShowNotificationsDropdown
  }

  const sidebarContexts = {
    default_modes: default_modes,
    selected_sidebar_organization: selected_sidebar_organization,
    setSelectedSidebarOrganization: setSelectedSidebarOrganization,
    selected_sidebar_tab: selected_sidebar_tab,
    setSelectedSidebarTab: setSelectedSidebarTab
  }

  return (
    <Router history={history}>
      <NotificationActionCableUpdate />
      <OrganizationTracker />
      <ContentGroupPopupContext.Provider value={contentGroupPopupContexts}>
        <SearchContext.Provider value={searchContexts}>
          <UserSummaryContext.Provider value={userSummaryContexts}>
            <TopbarContext.Provider value={topbarContexts}>
              <SidebarContext.Provider value={sidebarContexts}>
                <Header
                  setSidebar={setSidebar}
                  setWorkspaceContentGroupPopUp={setContentGroupPopUp}
                />

                <MobileSidebar />

                {isUserLoggedIn &&
                  <UserSummary userSummaryRef={userSummaryRef}/>
                }
                <div id='mainContainer' className={ !isUserLoggedIn ? "" : "layout__body--authenticated other_roles" }>
                  {
                    isUserLoggedIn &&
                    <Alert
                      message={ alert.message }
                      type={ alert.type }
                      clearAlert={ clearAlert }
                    />
                  }

                  <PrivateRoute path={`${workspaceBaseUrl}/:workspace_id`} component={ChatWindow} />
                  <Switch>
                    <PrivateRoute exact path='/dashboard' component={HomePage} />
                    <Route path='/users/password/edit' component={PasswordEdit} />
                    <Route path='/password/new' component={PasswordNew} />
                    <AdminRoute exact path='/organizations/:organization_id/admin/users/new' component={UserNew} />
                    <AdminRoute exact path='/organizations/:organization_id/admin/users' component={UserList} />
                    <AdminRoute path='/organizations/:organization_id/admin/users/:id/edit' component={EditUser} />
                    <AdminRoute exact path={`${workspaceBaseUrl}/:workspace_id/stages`} component={StageList} />
                    <AdminRoute exact path={`${templateStageBaseUrl}`} component={TemplateStageList} />
                    <PrivateRoute exact path='/' component={WorkspaceList} />
                    <PrivateRoute exact path='/organizations/:organization_id' component={WorkspaceList} />
                    <PrivateRoute exact path={`${userProfileBaseUrl}/:user_id`} component={UserProfileShow} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/collaborators`} component={CollaboratorList} />
                    <WorkspaceRouteWrapper exact path={`${workspaceBaseUrl}/:workspace_id/:mode`} component={WorkspaceShow} setContentGroupPopUp={setContentGroupPopUp} contentGroupPopUp={contentGroupPopUp} />
                    <WorkspaceRouteWrapper exact path={`${workspaceBaseUrl}/:workspace_id/:mode/content_groups/:content_group_id`} component={WorkspaceShow} setContentGroupPopUp={setContentGroupPopUp} contentGroupPopUp={contentGroupPopUp} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents`} component={ContentList} />
                    <WorkspaceRouteWrapper exact path={`${workspaceBaseUrl}/:workspace_id/content/:content_slug`} component={WorkspaceShow} setContentGroupPopUp={setContentGroupPopUp} contentGroupPopUp={contentGroupPopUp} />
                    {/*<PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/assigned_users/new`} component={AssignedUserNew} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/assigned_users`} component={AssignedUserList} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents/new`} component={ContentNew} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents/:content_id/replace`} component={ContentReplace} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents/:content_id/versions`} component={ContentVersion} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents/:content_id/versions/:version_id`} component={ContentVersionShow} />
                    <PrivateRoute exact path={`${workspaceBaseUrl}/:workspace_id/content_groups/:content_group_id/contents/:content_id/show`} component={ContentShow} />
                    <PrivateRoute exact path={`/invitations`} component={AcceptInvitation} />
                    <PrivateRoute exact path={`/invitations/workspace/:workspace_id/:workspace_invite_id`} component={AcceptWorkspaceInvitation} />
                    */}
                    <PrivateRoute exact path={`/notifications`} component={NotificationsPage} />
                    <Route exact path='/users/confirmation' component={ConfirmPage} />
                    <Route exact path='/users/unlock' component={UnlockAccountPage} />
                    <PrivateRoute path='/account/edit' component={UserProfileForm} />
                    <PrivateRoute path='/permission' component={UserPermission} />
                    <Route path='/register' component={RegisterPage} />
                    <Route path='/login' component={LoginPage} />
                    <Route exact path='/users/unlock/request' component={RequestUnlockAccount} />
                    <Route path='/confirmation/resend' component={ResendConfirmationMail} />
                    <Redirect from="*" to="/" />
                  </Switch>
                </div>
              </SidebarContext.Provider>
            </TopbarContext.Provider>

            <LiveChatWidget />
          </UserSummaryContext.Provider>
        </SearchContext.Provider>
      </ContentGroupPopupContext.Provider>
    </Router>
  );
}
export { App };
