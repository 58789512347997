import React, { useEffect, useState, useRef, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { userActions, work_spaceActions, organizationActions } from "../../../../actions";
import { WorkspaceInlineEdit } from '../../../../components/WorkspaceInlineEdit.js';
import { Navbar, NavDropdown, Button, Dropdown, Row, Col, Spinner } from "react-bootstrap";
import { adminBaseURL } from "../../../../utils/commonUtils";
import { history } from '../../../../helpers';
import '../../../stylesheets/workspace_page-show.scss';
import WorkSpaceEdit from '../../WorkSpacePage/edit';
import WorkspaceUsersPopup from '../../CollaboratorPage/list';
import WorkspaceSearchBar from '../../../../components/WorkspaceSearchBar';
import { WorkspaceBarContext } from '../../../../contexts/WorkspaceBarContext';

function MobileWorkspaceBar(props) {
  const {
          workspace_id,
          workspace_view,
          currentWorkspace,
          contentGroupPopUp,
          workspaces,
          loading
  } = props
  const dispatch = useDispatch();
  const workspaceBaseUrl = adminBaseURL("workspaces");
  const [workspace_logo_cached, setWorkspaceLogoCached] = useState(false);
  const [workspace_logo, setWorkspaceLogo] = useState();
  const [workspace_dropdown_cached, setWorkspaceDropdownCached] = useState(false);
  const { workspaceBarRef } = useContext(WorkspaceBarContext)
  const [mobileSearchActive, setMobileSearchActive] = useState(false);

  useEffect(() => {
    if(currentWorkspace?.logo?.thumb_url){
      setWorkspaceLogo(currentWorkspace?.logo?.thumb_url);
      setWorkspaceLogoCached(true);
    }
  }, []);


  useEffect(() => {

    if(workspace_logo_cached){
      return;
    }

    if (currentWorkspace?.logo?.thumb_url) {
      setWorkspaceLogo(currentWorkspace?.logo.thumb_url);
      setWorkspaceLogoCached(true);
    }

  }, [currentWorkspace?.logo]);

  useEffect(() => {
    if(workspace_dropdown_cached){
      return;
    }
    if(currentWorkspace){
      let serializer_type = 'list'

      dispatch(organizationActions.getWorkspaces(currentWorkspace.organization_id, serializer_type))

      setWorkspaceDropdownCached(true);
    }
  }, [currentWorkspace]);

  const sorted_workspaces = workspaces?.sort((a, b) => a.name.localeCompare(b.name)).filter(w => w.id !== currentWorkspace?.id);

  const handleWorkspaceDropDownToggle = (open) => {
    if(open) {
      let serializer_type = 'list'

      dispatch(organizationActions.getWorkspaces(currentWorkspace.organization_id, serializer_type))
    }
  }

  const renderWorkspaceLogo = ()=> {
    if(workspace_logo){
      return <img
                src={workspace_logo}
                className="workspace_thumbnail_logo"
              />
    }
  }

  return (
    <div>
      <div className={`workspace_page-title mobile-container  ${ currentWorkspace && !contentGroupPopUp.show ? '' : 'd-none'}`} ref={workspaceBarRef}>
        <div className="workspace-bar__details-wrapper py-1">
          <div className="workspace_title w-100">
            {
              mobileSearchActive ?
                renderWorkspaceLogo()
              :

              workspaces && workspaces.length > 0 &&
                <NavDropdown
                  title={
                    <>
                      { renderWorkspaceLogo() }
                      <span>
                        <span className="dropdown-toggle__text h4 font-weight-bold mr-3">
                          { currentWorkspace?.name }
                        </span>
                        {
                          sorted_workspaces.length > 1 &&
                          <i className="fa fa-caret-down"></i>
                        }
                      </span>
                    </>
                  }
                  id="basic-nav-dropdozwn"
                  onToggle={handleWorkspaceDropDownToggle}
                  className={`workspace_bar-dropdown`}
                >

                  {
                      sorted_workspaces.map((data, index) => (
                        <NavDropdown.Item
                          key={index}
                          href={`/organizations/${data.organization_id}`+workspaceBaseUrl + "/" + data.id + "/list"}
                          className="text-truncate"
                        >
                          {
                            data.logo && data.logo.thumb_url &&
                            <img
                              src={data.logo.thumb_url}
                              title={data.name}
                              alt={data.name}
                              height={32}
                              className={"mr-3"}
                            />
                          }

                          {data.name}
                        </NavDropdown.Item>
                      ))
                  }
                  { loading && sorted_workspaces.length > 1 &&
                    <div className="spinner-container">
                      <Spinner animation="border" />
                    </div>
                  }
                </NavDropdown>
            }

            <div className='w-100'>
              <WorkspaceSearchBar
                mobileSearchActive={mobileSearchActive}
                setMobileSearchActive={setMobileSearchActive}
                mobile={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.work_space.workspace_dropdown_loading,
    workspaces: state.work_space.work_spaces,
    currentWorkspace: state.work_space.currentWorkSpace
  };
};

export default connect(mapStateToProps, { userActions })(MobileWorkspaceBar);
