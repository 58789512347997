import React, { useEffect, useState } from 'react';
import {connect,  useDispatch, useSelector  } from 'react-redux';
import { NavDropdown, Dropdown, Link } from 'react-bootstrap';
import { workspace_userActions } from '../../../actions';
import CheckIcon from '../../../images/icons/check.svg';
import { tooltipConstants } from '../../../constants';
import { ElementTooltip } from '../../../components';

function RoleUpdateForm(props) {
  const dispatch = useDispatch();
  const { workspaceId, getCommonData, roles, user_id, userType} = props
  const setInitialCommonData = getCommonData
  const [roleName, setRoleName] = useState(roles)

  const currentRole = userType && roles && roles.length > 0 ? roles[0].workspace_role_name : roles[0]

  const has_role = currentRole === null || currentRole === undefined;

  const handleClick = (e, value) => {
    let successCallback = () => {}
    let values = {workspace_role_id: value, user_id: user_id}
    dispatch(workspace_userActions.updateWorkspaceUserRole(workspaceId, values, successCallback));
  }

  const renderTooltip = (option) => {
    switch(option?.name.toLowerCase()) {
      case 'team lead':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_TEAM_LEAD}
                  id={'select-team_lead'}
                  className={'unselected-tooltip'}
               />

      case 'coordinator':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_COORDINATOR}
                  id={'select-coordinator'}
                  className={'unselected-tooltip'}
               />

      case 'production':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_PRODUCTION}
                  id={'select-production'}
                  className={'unselected-tooltip'}
               />

      case 'spectator':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_SPECTATOR}
                  id={'select-spectator'}
                  className={'unselected-tooltip'}
               />

      case 'approver':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_APPROVER}
                  id={'select-approver'}
                  className={'unselected-tooltip'}
               />
      case 'contributor':
        return <ElementTooltip
                  tooltip_title={option?.name}
                  tooltip_text={tooltipConstants.ROLE_CONTRIBUTOR}
                  id={'select-contributor'}
                  className={'unselected-tooltip'}
               />

      default:
        return null
    }
  }

  const renderRoleTooltipText = (option_name) =>{
    switch(option_name) {
      case 'team lead':
        return tooltipConstants.ROLE_TEAM_LEAD
      case 'coordinator':
        return tooltipConstants.ROLE_COORDINATOR
      case 'production':
        return tooltipConstants.ROLE_PRODUCTION
      case 'spectator':
        return tooltipConstants.ROLE_SPECTATOR
      case 'approver':
        return tooltipConstants.ROLE_APPROVER
      case 'contributor':
        return tooltipConstants.ROLE_CONTRIBUTOR
      default:
        return null
    }
  }

  return (
    <>
      <ElementTooltip
        tooltip_title={has_role ? null : currentRole}
        tooltip_text={renderRoleTooltipText(currentRole?.toLowerCase())}
        id={'select-role_update-toggle'}
        className={'selected-tooltip'}
      />
      <Dropdown className="form-control role_select_options dropdown--with-check dropdown--with-tooltip">
        <Dropdown.Toggle
          id="dropdown-autoclose-true"
          className="dropdown-toggle nav-link cursor-pointer d-flex justify-content-between"
          as={'a'}
        >
          <span>{has_role ? 'Select Role' : currentRole}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { setInitialCommonData && setInitialCommonData.workspace_roles.map(option => (
            <Dropdown.Item
              href={"#"}
              key={'update_role-'+option.id}
              className={'d-flex justify-content-between'}
              onClick={(e) => handleClick(e, option.id) }
             >
              <span>{ option.name }</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getCommonData: state.dashboard.getCommonData,
  };
};

export default connect(mapStateToProps,{ workspace_userActions })(RoleUpdateForm);
