import React, { useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {useDetectOutsideClick} from "../../../../utils/useDetectOutsideClick";
import { UserThumbnails, CircleAddIcon } from '../../../../components';
import AssignedUserNew from "../../AssignedUserPage/new";
import { assigned_userActions } from '../../../../actions';

function ParticipantField(props) {
  const {
    can_access_assigned_user,
    assigned_users,
    workspace_id,
    content_group_id
  } = props;
  const dispatch = useDispatch();

  const activeDropDownPopup = (id) => {
    if(id) {
      setIsActive(true);
    }else {
      setIsActive(false);
    }
  }

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [showMobilePopup, setShowMobilePopup] = useState(false);

  const handleOpenAssignedUserEditPopup = (id, fromList) =>{
    const callback = (id, status) => {
      activeDropDownPopup(id);
      setShowMobilePopup(true);
    }

    dispatch(assigned_userActions.newAssignedUser(workspace_id, id, callback));
  }

  const handleClose = () => {
     setIsActive(false);
     setShowMobilePopup(false);
  }

  return (
    <div style={{position: 'relative'}} className="text-center">
      {(assigned_users?.length > 0) ?
          <div onClick={ can_access_assigned_user ? () => handleOpenAssignedUserEditPopup(content_group_id) : null}>
            <UserThumbnails users={assigned_users} disable_users_info={true}/>
          </div>
        :
          can_access_assigned_user &&
          <div
            className="button-icon cursor-pointer"
            onClick={() => handleOpenAssignedUserEditPopup(content_group_id)}
          >
            <CircleAddIcon />
          </div>
      }

      <AssignedUserNew
        dropdownRef={dropdownRef}
        isActive={isActive}
        showMobilePopup={showMobilePopup}
        workspace_id={workspace_id}
        content_group_id={content_group_id}
        closeDropDownPopup={handleClose}
        fromList={false}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.content_group.loading
  };
};

export default connect(mapStateToProps, {})(ParticipantField);
