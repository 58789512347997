import React, { useEffect, useState, useContext } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import {
  ListNavIcon,
  KanbanNavIcon,
  CalendarNavIcon,
  NotificationNavIcon
} from '../../../../components';
import { work_spaceActions } from "../../../../actions";
import { TopbarContext } from '../../../../contexts/TopbarContext';

function WorkspaceNavigation(props) {
  const {
    workspace_view,
    currentWorkspace
  } = props
  const dispatch = useDispatch();
  const {
    showNotificationsDropdown,
    setShowNotificationsDropdown
  } = useContext(TopbarContext);

  const isActive = (tab) => {
    return (workspace_view === tab) && !showNotificationsDropdown
  }

  const handleClickTab = (view) => {
    setShowNotificationsDropdown(false);

    let noReload = false
    dispatch(work_spaceActions.setWorkspaceView(currentWorkspace?.id, view, noReload));
  }

  if(currentWorkspace === undefined || currentWorkspace === null){
    return null;
  }

  return (
    <Navbar
      className="mobile_navigation p-0"
      sticky="bottom"
    >
      <div
        className="mobile_navigation-button_group w-100 h-100 d-flex align-item-center"
      >
        <Button
          size={"sm"}
          variant={"transparent"}
          className={isActive("list") ? "active" : ""}
          onClick={() => handleClickTab("list")}
        >
          <ListNavIcon
            className="m-auto d-block"
            height={"24"}
            width={"24"}
          />
          List
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          className={isActive("board") ? "active" : ""}
          onClick={() => handleClickTab("board")}
        >
          <KanbanNavIcon
            className="m-auto d-block"
            height={"24"}
            width={"24"}
          />
          Board
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          className={isActive("calendar") ? "active" : ""}
          onClick={() => handleClickTab("calendar")}
        >
          <CalendarNavIcon
            className="m-auto d-block"
            height={"24"}
            width={"24"}
          />
          Calendar
        </Button>
        <Button
          size={"sm"}
          variant={"transparent"}
          className={showNotificationsDropdown ? "active" : ""}
          onClick={() => setShowNotificationsDropdown(true)}
        >
          <NotificationNavIcon
            className="m-auto d-block"
            height={"24"}
            width={"24"}
          />
          Notifications
        </Button>
      </div>
    </Navbar>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.organization.loading,
    workspace_view: state.work_space.workspaceView,
    currentWorkspace: state.work_space.currentWorkSpace,
  };
};

export default connect(mapStateToProps, { })(WorkspaceNavigation);
