import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import { renderToString } from 'react-dom/server';
import { connect, useDispatch } from "react-redux";
import {
  ContentLabel,
  CircleAddIcon
} from '../../../../components';
import { dateTimeFormatter, formatDay } from '../../../../utils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import MobileEventCalendarCard from './mobileEventCalendarCard.js';

function MobileCalendar(props) {
  const {
    onBeforeCreateSchedule,
    workspace_id,
    defaultActiveStartDate,
    setDate,
    calendarRef,
    events,
    can_access_content,
    onClickEvent,
    can_access_edit_label,
    updated_content_group,
    created_content_group,
    labelDrpdwnContainerRef,
    showLabelDrpdwn,
    setShowLabelDrpdwn
  } = props;
  const dispatch = useDispatch();
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const [dateFormat, setDateFormat] = useState(new Date(defaultActiveStartDate));
  const [selectedDate, setSelectedDate] = useState(new Date(defaultActiveStartDate));
  const [selectedEvents, setSelectedEvents] = useState([]);

  const formatDayName = useCallback((date, format) => {
    return weekDays[formatDay(date, format)]
  }, []);

  const checkTheDateForEvents = useCallback((date) => {
    return events.filter(item => formatDay(item.due_date, 'MM/DD/YYYY') === formatDay(date, 'MM/DD/YYYY'))
  }, [events]);

  const hasEvent = ({ activeStartDate, date, view }) => {

    if(checkTheDateForEvents(date).length > 0){
      return 'has-events'
    }else {
      return null
    }
  }

  const onChange = ({ action, activeStartDate, value, view }) => {
    setDate(activeStartDate)
  }

  useEffect(() => {
    setDateFormat(new Date(defaultActiveStartDate))
  }, [defaultActiveStartDate]);

  useEffect(() => {
    if(updated_content_group){
      let dupList = checkTheDateForEvents(selectedDate);
      let index = dupList.findIndex((element) => element.id == updated_content_group.id);

      if ( index !== -1){
        dupList[index] = updated_content_group

        setSelectedEvents(prevState => dupList.filter(item => formatDay(item.due_date, 'MM/DD/YYYY') === formatDay(selectedDate, 'MM/DD/YYYY')))
      }
    }
  }, [updated_content_group]);

  useEffect(() => {
    if(selectedDate){
      let sorted_events = checkTheDateForEvents(selectedDate)
                            .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
      setSelectedEvents(sorted_events)
    }else{
      setSelectedEvents([])
    }
  }, [selectedDate, events]);

  const navigationLabel = ({ date, label, locale, view }) => {
    return `${formatDay(date, 'MMMM YYYY')}`
  };

  return (
    <>
      <Calendar
        onClickDay={(value, event) => setSelectedDate(value)}
        formatShortWeekday={(locale, date) => formatDayName(date, 'd')}
        showNeighboringMonth={false}
        tileClassName={hasEvent}
        calendarType={'gregory'}
        onActiveStartDateChange={onChange}
        defaultActiveStartDate={dateFormat}
        navigationLabel={navigationLabel}
      />

      <div className="mobile-calendar_cards m-4">
        {
          selectedEvents?.length > 0 &&
          selectedEvents.map((event) => (
            <MobileEventCalendarCard
              event={event}
              workspace_id={workspace_id}
              can_access_edit_label={can_access_edit_label}
              labelDrpdwnContainerRef={labelDrpdwnContainerRef}
              showLabelDrpdwn={showLabelDrpdwn}
              setShowLabelDrpdwn={setShowLabelDrpdwn}
            />
          ))
        }

        {
          can_access_content && selectedDate &&
          <div
            className="mobile-calendar_card w-100 mb-3 add-card_button cursor-pointer d-flex"
            onClick={() => onBeforeCreateSchedule({end: selectedDate})}
          >
            <div
              className="mr-2 d-flex align-items-center "
            >
              <CircleAddIcon color={"#8C8B9A"} />
            </div>
            Add Card
          </div>
        }
      </div>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    loading: state.work_space.loading,
    updated_content_group: state.content_group.updated_content_group,
    created_content_group: state.content_group.created_content_group
  };
};

export default connect(mapStateToProps, {  })(MobileCalendar);
