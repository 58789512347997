import React, { useEffect, useContext } from "react";
import { MessageBubble } from './';
import { useMediaQuery } from 'react-responsive';
import InfiniteScroll from 'react-infinite-scroll-component';
import { dateTimeFormatter } from '../utils/';
import { ChatContext } from '../contexts/ChatContext'
import { mediaQueryConstants } from '../constants';

const ChatMessageList = (props) => {
  const {
    comments,
    bottomRef,
    activeTab,
    getCommentRef,
    showActivities,
    highlightedCommentId,
    selectCommentToReply,
    commentToReplyId,
    workspace_id,
    setImagePopUp,
    renderParentComment,
    showTeamTabButtons,
    has_next_page,
    nextPage,
    organization_id
  } = props;

  const {
    can_access_comment
  } = useContext(ChatContext);

  const isLargeScreen = useMediaQuery({ query: `(max-width: ${mediaQueryConstants.LARGE}px)` });

  const renderComment = (data) => {
    if (data.is_notification){
      if (data.description !== '-'){
        return <>
                <div className={`message message--notification`}>
                  <div className="description">
                    <div dangerouslySetInnerHTML={{ __html:
                      data.description
                    }} />
                  </div>
                  <div className="message-date">{dateTimeFormatter(data.created_at)}</div>
                </div>
               </>
      }
    } else {
      return <>
              <MessageBubble
                own={ data.is_user }
                extra_heading_content={
                  data.content && data.content.id &&
                    <div style={{textDecoration: "underline", marginBottom: 5}}>
                      <b>Content:</b> {data.content.id}
                    </div>
                }
                id={ data.id }
                user_name={ data.user }
                user_id={data.user_id}
                user_name_initials={ data.user_name_initials }
                user_icon={ data.user_profile }
                message={ data.description }
                event_type={ data.event_type }
                date={ dateTimeFormatter(data.created_at) }
                selectCommentToReply={selectCommentToReply}
                highlighted={ data.id == commentToReplyId }
                attachments={data.attachments}
                workspace_id={workspace_id}
                content_group_id={ data.content_group_id }
                setImagePopUp={ setImagePopUp }
                renderParentComment={ renderParentComment }
                parent_id={ data.parent_id }
                data={data}
                organization_id={ organization_id }
              />
             </>
    }
  }

  const itemContent = (index, sessionData) => (
    <div
      className={`${highlightedCommentId === sessionData.id ? 'hovered' : null} clearfix`}
      key={ sessionData.id }
      ref={(ref) => getCommentRef(ref, sessionData.id)}
    >
      { renderComment(sessionData) }
    </div>
  )

  const chatHeight = () =>{
    let offsetHeight = can_access_comment ?
                        isLargeScreen ? 128 : 168
                      :
                        isLargeScreen ? 30 : 88

    return offsetHeight
  }

  return (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: `calc(100% - ${chatHeight()}px)`,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse'
      }}
      className="chat-list vertical-scroll_thin"
    >
      <div ref={bottomRef} />
      <InfiniteScroll
        dataLength={comments?.length}
        scrollableTarget={"scrollableDiv"}
        inverse={true}
        next={nextPage}
        hasMore={has_next_page || false}
        style={{overflow: 'initial'}}
      >
        {comments.map((comment, index) => (
          itemContent(index, comment)
        ))}
      </InfiniteScroll>
    </div>
  );
}


export { ChatMessageList };
